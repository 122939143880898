var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"structureSplice"},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("historyDetail.txt27")))]),_c('div',{staticClass:"content"},[_c('span',{staticClass:"structureText"},[_vm._v(_vm._s(_vm.$t("historyDetail.txt59")))]),_c('div',{staticClass:"structureImgTop"},[_c('div',{staticClass:"structureImg"},[_c('el-image',{staticStyle:{"width":"98%","height":"98%"},attrs:{"src":_vm.structureImg,"fit":"contain"}},[_c('div',{staticClass:"image-slot",attrs:{"slot":"error"},slot:"error"},[_c('img',{attrs:{"src":require("@/newImg/structure.png"),"alt":""}}),_c('span',[_vm._v(_vm._s(_vm.$t("historyDetail.txt70")))])])])],1),_c('div',{staticClass:"structureColor"},_vm._l((_vm.structurecolor),function(item,index){return _c('div',{key:index,staticClass:"itemColor"},[_c('span',{staticClass:"num"},[_vm._v(_vm._s(index + 1)+".")]),_c('span',{staticClass:"color",style:({ background: ("rgb(" + (item.rgb) + ")") })}),_c('span',{staticClass:"name"})])}),0)]),_vm._l((Math.ceil(_vm.structureList.length / 2)),function(item,index){return _c('div',{key:index,staticClass:"itemLine"},[_c('div',{staticClass:"itemLeft itemContent"},[_c('div',{staticClass:"structureList"},[_c('div',{staticClass:"item",style:(("" + (_vm.copystructureList.length == 0
                  ? ''
                  : _vm.copystructureList[index * 2].w
                  ? 'align-items: start;'
                  : '') + (_vm.copystructureList.length == 0
                  ? ''
                  : _vm.copystructureList[index * 2].h
                  ? ' justify-content: start;'
                  : '')))},_vm._l((_vm.structureList[index * 2].keyList),function(itemW,indexW){return _c('div',{key:indexW + 100,staticClass:"itemW"},_vm._l((itemW),function(itemH,indexH){return _c('div',{key:indexH + 200,class:indexH == 0
                      ? indexW == 0
                        ? 'itemH leftBorder topBorder'
                        : 'itemH leftBorder'
                      : indexW == 0
                      ? 'itemH topBorder'
                      : 'itemH'},[_c('img',{attrs:{"src":_vm.thStrList[itemH - 1],"alt":""}})])}),0)}),0)]),_c('div',{staticClass:"colorlist"},_vm._l((_vm.structureList[index * 2].colorList),function(itemW,indexW){return _c('div',{key:indexW + 300,staticClass:"itemColor",style:({ background: ("rgb(" + itemW + ")") })})}),0)]),(_vm.structureList[index * 2 + 1])?_c('div',{staticClass:"itemRight itemContent"},[_c('div',{staticClass:"structureList"},[_c('div',{staticClass:"item",style:(("" + (_vm.copystructureList.length == 0
                  ? ''
                  : _vm.copystructureList[index * 2 + 1].w
                  ? 'align-items: start;'
                  : '') + (_vm.copystructureList.length == 0
                  ? ''
                  : _vm.copystructureList[index * 2 + 1].h
                  ? 'justify-content: start;'
                  : '')))},_vm._l((_vm.structureList[index * 2 + 1]
                  .keyList),function(itemW,indexW){return _c('div',{key:indexW + 100,staticClass:"itemW"},_vm._l((itemW),function(itemH,indexH){return _c('div',{key:indexH + 200,class:indexH == 0
                      ? indexW == 0
                        ? 'itemH leftBorder topBorder'
                        : 'itemH leftBorder'
                      : indexW == 0
                      ? 'itemH topBorder'
                      : 'itemH'},[_c('img',{attrs:{"src":_vm.thStrList[itemH - 1],"alt":""}})])}),0)}),0)]),_c('div',{staticClass:"colorlist"},_vm._l((_vm.structureList[index * 2 + 1]
                .colorList),function(itemW,indexW){return _c('div',{key:indexW + 300,staticClass:"itemColor",style:({ background: ("rgb(" + itemW + ")") })})}),0)]):_vm._e()])})],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }