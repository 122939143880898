<template>
  <div class="detailNav">
    <!-- 详情导航 -->
    <div class="backHistory">
      <div class="detailLink" @click="backList">
        <div class="item" >
          <img src="@/images/back.png" alt="" />
        </div>
        <div class="text">
          <span> 详情页 </span>
        </div>
      </div>
    </div>
    <!--修改删除副本下载-->
    <div class="editDel">
      <div @click="HZClick" v-show="finishProcess">
        <a class="hz">
          <img src="@/images/RZImg.png" alt="" />
          <span>染整</span>
        </a>
      </div>
      <div @click="copyDesign('copy')" class="copy">
        <img src="@/images/copy.png" alt="" />
        <span>副本</span>
      </div>
      <div @click="download">
        <a class="downLoad">
          <img src="@/images/downLoadPdf.png" alt="" />
          <span>导出</span>
        </a>
      </div>
      <div @click="deleteClothDesign('delete')">
        <img src="@/images/delete.png" alt="" />
        <span style="color: #d60000">删除</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "headers",
  props: ["finishProcess"],
  data() {
    return {};
  },
  methods: {
    backList() {
      this.$router.push({
        path: "/history",
      });
    },
    HZClick() {
      this.$emit("HZClick");
    },
    copyDesign(value) {
      this.$emit("copyDesign", value);
    },
    download() {
      this.$emit("download");
    },
    deleteClothDesign(value) {
      this.$emit("deleteClothDesign", value);
    },
  },
  computed: {},
  mounted() {},
};
</script>

<style lang="less" scoped>
.detailNav {
  width: calc(100vw - 90px);
  height: 104px;
  background: #ffff;
  padding-left: 24px;
  box-sizing: border-box;
  position: absolute;
  left: -24px;
  top: -130px;
  display: flex;
  justify-content: space-between;
  z-index: 10;
  .backHistory {
    width: 20%;
    display: flex;
    align-items: center;
    .detailLink {
      font-size: 24px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      height: 28px;
      display: flex;
      flex-direction: row;
      .item {
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-right: 10px;
      }
      .text {
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
      span {
        width: 72px;
        height: 28px;
        margin-right: 0;
        font-size: 24px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.85);
        line-height: 28px;
      }
      img {
        width: 24px;
        height: 24px;
      }
    }
  }

  .editDel {
    display: flex;
    margin-top: 30px;
    margin-right: 20px;

    div,
    a {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      cursor: pointer;

      .icon {
        width: 24px;
        height: 24px;
        margin-bottom: 7px;
      }

      img {
        margin-bottom: 10px;
        width: 24px;
        height: 24px;
      }

      span {
        width: 41px;
        height: 18px;
        font-size: 16px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #333333;
        line-height: 20px;
      }

      + div {
        margin-left: 47px;
      }
    }

    .copy {
      span {
        width: 66px;
      }
    }

    .hz {
      img {
        width: 22px;
        height: 24px;
      }

      span {
        // width: 104px;
      }
    }
  }
}
</style>