<template>
  <div>
    <!-- 详情面料信息 -->
    <div class="fabricInfo">
      <p class="title">(二)面料信息</p>
      <div class="content">
        <p>
          <span class="headItem topheadItem">品号</span
          ><span class="endItem">{{ draperInfo.productCode }}</span>
        </p>
        <p>
          <span class="headItem topheadItem">品名</span
          ><span class="endItem">{{ draperInfo.productName }}</span>
        </p>
        <p>
          <span class="headItem topheadItem">客户名称</span
          ><span class="endItem">{{ draperInfo.customerName }}</span>
        </p>
        <p>
          <span class="headItem topheadItem">织布单位</span
          ><span class="endItem">{{ draperInfo.machineTeam }}</span>
        </p>
        <!-- <p>
          <span class="headItem topheadItem">面料尺寸</span
          ><span class="endItem">{{ draperInfo.fabricSize }}</span>
        </p> -->
        <p>
          <span class="headItem topheadItem">线长 （mm/50N）</span
          ><span class="endItem">{{
            draperInfo.lineLength > 0 ? draperInfo.lineLength : ""
          }}</span>
        </p>
        <p>
          <span class="headItem topheadItem">横密</span
          ><span class="endItem">{{ draperInfo.transverseDensity }}</span>
        </p>
        <p>
          <span class="headItem">纵密</span
          ><span class="endItem">{{ draperInfo.longitudinalDensity }}</span>
        </p>
        <p>
          <span class="headItem">克重</span
          ><span class="endItem">{{
            draperInfo.gramWeight > 0 ? draperInfo.gramWeight + "g/m2" : ""
          }}</span>
        </p>
        <p>
          <span class="headItem">设备名称</span
          ><span class="endItem">{{ machineName }}</span>
        </p>
        <p>
          <span class="headItem">基本组织</span
          ><span class="endItem">{{ draperInfo.basicOrganiza }}</span>
        </p>
        <!-- class="dateItem" -->
        <p>
          <span class="headItem">日期</span
          ><span class="endItem">{{ draperInfo.createTime }}</span>
        </p>
        <p class="lastItem">
          <span class="headItem">备注</span
          ><span class="endItem"
            >{{ draperInfo.remark.length > 0 ? draperInfo.remark : "无" }}
          </span>
        </p>
        <!-- <p style="width: 75%"></p> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "fabricInfo",
  props: ["draperInfo", "machineName"],
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
// 面料信息
.fabricInfo {
  background-color: #ffffff;
  height: auto;
  width: 100%;
  border-radius: 8px;
  padding: 24px;
  box-sizing: border-box;
  margin-bottom: 24px;
  .title {
    width: 186px;
    height: 20px;
    font-size: 22px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 20px;
    letter-spacing: 6px;
    margin-bottom: 32px;
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    align-items: left;
    border-left: 1px solid #e9e9e9;
    // border-top: 1px solid #e9e9e9;
    width: 100%;

    p {
      box-sizing: border-box;
      width: 16.6%;
      height: 170px;

      // border-right: 1px solid #e9e9e9;
      // border-bottom: 1px solid #e9e9e9;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      span {
        height: 85px;
        width: 100%;
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 30px;
        letter-spacing: 2px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .headItem {
        background: #f6f8fa;
        font-weight: 500;
        width: 100%;
        box-sizing: border-box;
        border-right: 1px solid #e9e9e9;
      }

      .topheadItem {
        border-top: 1px solid #e9e9e9;
      }

      .endItem {
        flex: 1;
        width: 100%;
        border-right: 1px solid #e9e9e9;
        border-bottom: 1px solid #e9e9e9;
        word-break: break-all;
        box-sizing: border-box;
      }

      .tempItem {
        background: #ffffff;
        border-right: none;
      }
    }

    .dateItem {
      width: 50%;
    }

    .remark {
      width: 50%;
      height: 66px;
      border-right: 1px solid #e9e9e9;
      border-bottom: 1px solid #e9e9e9;
      display: flex;

      .endItem {
        width: 765px;
        text-align: left;
        // justify-content: flex-start;
        margin-left: 10px;
      }
    }
  }
}
</style>