<template>
  <div class="yarnInfo">
    <!-- 详情纱线 -->
    <!--纱线信息-->
    <span class="title">(一)纱线信息</span>
    <el-table
      :data="yarnList"
      class="yarnStoreTable"
      :fit="true"
      size="mini"
      border
      :row-style="{ height: '50px' }"
      :header-cell-style="{ background: '#F6F8FA', 'font-weight': 500 }"
    >
      <el-table-column prop="yarnColor" label="缩略图" align="center">
        <template slot-scope="scope">
          <p>
            <span
              class="bgColor"
              style="display: inline-block"
              :style="{
                background: scope.row.yarnColor,
                border:
                  scope.row.yarnColor == '#FFFFFF' ? '1px solid #e9e9e9' : '',
              }"
            ></span>
            {{ scope.row.colorCode }}
          </p>
        </template>
      </el-table-column>
      <el-table-column prop="yarnSerialNumber" label="纱线编号" align="center">
        <template slot-scope="scope">
          {{
            scope.row.yarnSerialNumber
              ? scope.row.yarnSerialNumber
              : "纱线编号缺失"
          }}
        </template>
      </el-table-column>
      <el-table-column prop="yarnColor" label="色号" align="center">
        <template slot-scope="scope">
          {{ scope.row.yarnColor }}
        </template>
      </el-table-column>
      <el-table-column prop="element" label="原料成分" align="center">
        <template slot-scope="scope">
          {{ scope.row.element }}
        </template>
      </el-table-column>
      <el-table-column prop="yarnCount" label="纱支" align="center">
        <template slot-scope="scope">
          {{ scope.row.yarnCount }}
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "yarnListmodel",
  props:["yarnList"],
  data() {
    return {
      
    };
  },
};
</script>

<style  lang="less" scoped>
// 纱线信息
.yarnInfo {
  background-color: #fff;
  max-height: 391px;
  min-height: 150px;
  width: 100%;
  padding: 24px;
  border-radius: 8px;
  box-sizing: border-box;
  margin-top: 24px;
  margin-bottom: 24px;

  .title {
    width: 186px;
    height: 20px;
    font-size: 22px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 20px;
    letter-spacing: 6px;
    margin-top: 34px;
  }

  .el-table {
    margin-top: 32px;
    width: 100%;
    max-height: 240px;
    min-height: 90px;
    overflow-y: overlay;
    // border-bottom: 1px solid #ebeef5;
    box-sizing: border-box;
    margin-right: 25px;

    font-size: 18px !important;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400 !important;
    color: #333333 !important;
    line-height: 30px;
    letter-spacing: 1px;

    .bgColor {
      width: 22px;
      height: 22px;
    }
  }

  .yarnStoreTable/deep/.el-table__header {
    .el-table__cell {
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400 !important;
      color: #333333 !important;
      line-height: 30px;
      letter-spacing: 1px;
    }
  }

  /* 设置滚动条的样式 */
  .yarnStoreTable::-webkit-scrollbar {
    width: 4px;
    height: 8px;

    border-radius: 8px;
    cursor: pointer;
  }

  /* 滚动槽 */
  .yarnStoreTable::-webkit-scrollbar-track {
    border-radius: 8px;
    // background: #666;
    cursor: pointer;
  }

  /* 滚动条滑块 */
  .yarnStoreTable::-webkit-scrollbar-thumb {
    border-radius: 6px;
    cursor: pointer;
    background: #666;
    background: rgba(0, 0, 0, 0.24);
  }

  /* 滚动条滑块 */
  .yarnStoreTable::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.4);
  }
}

.yarnInfo/deep/.el-table tbody tr:hover > td {
  background-color: transparent !important;
}
</style>