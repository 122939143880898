<template>
  <div>
    <!--历史详情-->
    <div class="historyInfoContiner" ref="yarnInfo">
      <!-- 顶部导航 -->
      <div class="DetailpageTop">
        <div class="Top"></div>
        <div class="TopBtn">
          <div class="back" @click="backBtnClick()">
            <img src="@/images/back.png" alt="" />
            <span>{{ $t("historyDetail.txt1") }}</span>
          </div>
          <div class="rightBtn">
            <div @click="copyDesign('copy')" class="copy btn">
              <img src="@/newImg/Review_fill@2x.png" class="img1" alt="" />
              <span>{{ $t("historyDetail.txt2") }}</span>
            </div>
            <div class="export btn" @click="isShowExport = true">
              <img src="@/images/daochu.png" alt="" />
              <span>{{ $t("historyDetail.txt3") }}</span>
            </div>
            <div class="delete btn" @click="deleteClick('delete')">
              <img src="@/images/shanchu.png" alt="" />
              <span>{{ $t("historyDetail.txt4") }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 面料信息 -->
      <div class="fabricInfo">
        <div class="title">{{ $t("historyDetail.txt12") }}</div>
        <div class="list">
          <div
            class="item"
            v-for="(item, index) in fabricInfoData"
            :key="index"
          >
            <div class="itemTop">{{ item.title }}</div>
            <div class="itemCon" v-if="item.con != ''">{{ item.con }}</div>
            <div class="itemCon text" v-if="item.con == '' && item.title != ''">
              —
            </div>
          </div>
        </div>
      </div>
      <!-- 纱线 -->
      <div class="yarn">
        <div class="title">{{ $t("historyDetail.txt5") }}</div>
        <div class="yarnListTable">
          <el-table
            :data="yarnList"
            class="yarnStoreTable"
            border
            style="width: 100%"
            :span-method="arraySpanMethod"
          >
            <el-table-column
              prop="typeName"
              label=""
              width="180"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="yarnColor"
              :label="$t('historyDetail.txt7')"
              width="180"
              align="center"
            >
              <template slot-scope="scope">
                <p class="bgColor">
                  <span
                    :style="{
                      background: scope.row.yarnColor,
                    }"
                  ></span>
                  {{ scope.row.colorCode }}
                </p>
              </template>
            </el-table-column>
            <el-table-column
              prop="nr"
              :label="$t('historyDetail.txt8')"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="yarnColor"
              :label="$t('historyDetail.txt9')"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="rawMaterials"
              :label="$t('historyDetail.txt10')"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="yarnCount"
              :label="$t('historyDetail.txt11')"
              align="center"
            >
            </el-table-column>
          </el-table>
        </div>
      </div>
      <!-- 织物效果图 -->
      <div :class="showFD ? 'FDModel' : 'renderMap'">
        <div class="topTitle">
          <div class="title">{{ $t("historyDetail.txt23") }}</div>
          <div class="FDBtn" @click="SXModelClick" v-show="showFD">
            <img src="@/images/suoXiao.png" alt="" />
            <span>{{ $t("historyDetail.txt35") }}</span>
          </div>
        </div>
        <div class="content">
          <div class="topItem" v-show="!showFD">
            <div class="top">
              <span class="titleTop">{{ $t("historyDetail.txt24") }}</span>
              <div class="FDBtn" @click="FDModelClick">
                <img src="@/images/FD.png" alt="" />
                <span>{{ $t("historyDetail.txt25") }}</span>
              </div>
            </div>
            <div class="rightBoxTop" v-show="!FDType">
              <div class="boxTxt">{{ $t("historyDetail.txt26") }}</div>
              <div class="sizeImg">
                <img
                  src="@/images/sxSize.png"
                  alt=""
                  @click="setImgsize(1)"
                  v-show="this.sizeNum <= 1"
                />
                <img
                  src="@/images/hssxSize.png"
                  alt=""
                  @click="setImgsize(1)"
                  v-show="this.sizeNum > 1"
                />
                <img
                  src="@/images/fdSize.png"
                  alt=""
                  @click="setImgsize(2)"
                  v-show="this.sizeNum >= 10"
                />
                <img
                  src="@/images/hsfdSize.png"
                  alt=""
                  @click="setImgsize(2)"
                  v-show="this.sizeNum < 10"
                />
              </div>
            </div>
          </div>
          <!-- 左上角icon -->
          <div class="out" v-show="showFD" @click="SXModelClick">
            <img :src="sceneBgType == 1 ? outImg : outImg2" alt="" />
          </div>
          <div class="outBack" v-show="showFD" @click="SXModelClick">
            <img :src="sceneBgType == 1 ? backImg : backImg2" alt="" />
          </div>
          <div class="jacquar">
            <div class="RenderBox">
              <Render
                :jacquar="true"
                :id="designId"
                class="Render"
                ref="renderComp"
                key="minRender"
                v-show="showFD ? !conmodelType : btnIndex != 4"
              />

              <!-- 左下角icon -->
              <div class="functionModel" v-show="btnIndex != 4 && showFD">
                <el-popover
                  placement="top-start"
                  :popper-class="
                    sceneBgType == 1
                      ? 'modelPopover'
                      : 'modelPopover modelPopover2'
                  "
                  trigger="click"
                >
                  <div class="popperTop">
                    <span>{{ $t("historyDetail.txt60") }}</span>
                  </div>
                  <div class="popperContent">
                    <el-radio-group
                      v-model="radio"
                      @change="modelChangeHandler"
                    >
                      <el-radio label="1" class="oneRadio">{{
                        $t("historyDetail.txt62")
                      }}</el-radio>
                      <el-radio label="2">{{
                        $t("historyDetail.txt63")
                      }}</el-radio>
                    </el-radio-group>
                  </div>
                  <div
                    :class="
                      sceneBgType == 1
                        ? 'modelTypeBtn modelType1'
                        : 'modelTypeBtn modelType2'
                    "
                    slot="reference"
                  >
                    <img
                      :src="sceneBgType == 1 ? modelImg : modelImg2"
                      alt=""
                    />
                    <span>{{ $t("historyDetail.txt60") }}</span>
                  </div>
                </el-popover>

                <div class="centerBorder"></div>

                <el-popover
                  placement="top-start"
                  :popper-class="
                    sceneBgType == 1
                      ? 'modelPopover '
                      : 'modelPopover modelPopover2'
                  "
                  trigger="click"
                >
                  <div class="popperTop">
                    <span>{{ $t("historyDetail.txt61") }}</span>
                  </div>
                  <div class="popperContent">
                    <el-radio-group
                      v-model="radio2"
                      @change="sceneBgChangeHandler"
                    >
                      <el-radio label="1" class="oneRadio">{{
                        $t("historyDetail.txt64")
                      }}</el-radio>
                      <el-radio label="2">{{
                        $t("historyDetail.txt65")
                      }}</el-radio>
                    </el-radio-group>
                  </div>
                  <div
                    :class="
                      sceneBgType == 1
                        ? 'modelTypeBtn modelType1'
                        : 'modelTypeBtn modelType2'
                    "
                    slot="reference"
                  >
                    <img
                      :src="sceneBgType == 1 ? sceneImg : sceneImg2"
                      alt=""
                    />
                    <span>{{ $t("historyDetail.txt61") }}</span>
                  </div>
                </el-popover>
              </div>
            </div>
            <div class="rightBox" v-show="!showFD">
              <div class="imgBox">
                <!-- <img :src="planeImg" alt="" /> -->
                <div :style="getFrontUrlMap()" class="img"></div>
              </div>
              <div class="FDSizeBtn" v-show="FDType && showImg">
                <img
                  src="@/images/fdSize.png"
                  alt=""
                  @click="setImgsize(2)"
                  v-show="this.sizeNum >= 6"
                />
                <img
                  src="@/images/hsfdSize.png"
                  alt=""
                  @click="setImgsize(2)"
                  v-show="this.sizeNum < 6"
                />
                <img
                  src="@/images/sxSize.png"
                  alt=""
                  @click="setImgsize(1)"
                  v-show="this.sizeNum <= 1"
                />
                <img
                  src="@/images/hssxSize.png"
                  alt=""
                  @click="setImgsize(1)"
                  v-show="this.sizeNum > 1"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--结构参数 -->
      <structList
        class="showBottom"
        :structureList="structureList"
        :thStrList="thStrList"
        :structureImg="structureImg"
        :structurecolor="structurecolor"
        :structureListBorderType="structureListBorderType"
      />
    </div>
    <exportDialog
      :dialogVisible="isShowExport"
      @cancelDialog="cancelExport"
      @confirmDialog="confirmExport"
    />
    <CommonDialog
      :dialogVisible="isShowDialog"
      :content="setContent"
      :confirmContent="setConfirmContent"
      @cancelDialog="cancelDialog"
      @confirmDialog="confirmDialog"
    ></CommonDialog>
  </div>
</template>

<script>
import CommonDialog from "@/components/commonDialog/CommonDialog.vue";
import exportDialog from "@/components/exportDialog/exportDialog.vue";
import "vue-custom-scrollbar/dist/vueScrollbar.css";
import headeraaa from "../component/modelPage/header.vue";
import yarnListDetail from "../component/modelPage/yarnListmodel.vue";
import fabricInfo from "../component/modelPage/fabricModel.vue";
import structList from "../component/modelPage/structureSplice.vue";

import { getClothDetail, delHistory } from "@/api/history/history";
import Render from "@/components/Render/Render";
import jacquardWeaveMeanWork from "@/components/jacquardWeaveMeanWork/jacquardWeaveMeanWork";
export default {
  name: "THHistoryDetail",
  components: {
    CommonDialog,
    Render,
    jacquardWeaveMeanWork,
    headeraaa,
    yarnListDetail,
    fabricInfo,
    structList,
    exportDialog,
  },
  data() {
    return {
      // num:0025
      DetailActive3D: 1,
      designId: "",
      renderType: false,
      clothDetail: {},
      isShowDialog: false,
      setContent: "",
      setConfirmContent: "",
      currentDialogTitle: "",
      draperInfo: {
        productCode: "",
        productName: "",
        remark: "",
        createTime: "",
        roadNum: "",
        categoryName: "",
      },
      yarnList: [],
      finishProcess: false,
      thStrList: [
        require("@/images/thcq.png"),
        require("@/images/thjq.png"),
        require("@/images/thfx.png"),
      ],
      structureList: [],
      structureListBorderType: 0,
      zoomNum: 1,
      t: "",
      btnIndex: 0,
      showFD: false,
      btnzyType: 2,
      designResultPath: {},
      sizeRepeatX: 0,
      sizeRepeatY: 0,
      img2D: "",
      coypModelindex: 1,
      zfImgIndex: 2,
      scrollTop: "",
      HeadList: [
        this.$t("historyDetail.txt6"),
        this.$t("historyDetail.txt7"),
        this.$t("historyDetail.txt8"),
        this.$t("historyDetail.txt9"),
        this.$t("historyDetail.txt10"),
        this.$t("historyDetail.txt11"),
      ],
      fabricInfoData: [],
      FDrenderType: false,
      conmodelType: false,
      img2dW: 0,
      img2dH: 0,

      sizeNum: 1, //2d图放大缩小
      planeImg: "", //2d展示平面图
      planeImgtype: true, //当前显示正面还是反面
      FDType: false,
      isShowExport: false,
      materialQuality: [
        this.$t("pup.txt24"),
        this.$t("pup.txt25"),
        this.$t("pup.txt26"),
        this.$t("pup.txt27"),
        this.$t("pup.txt28"),
        this.$t("pup.txt29"),
        this.$t("pup.txt30"),
        this.$t("pup.txt31"),
        this.$t("pup.txt32"),
        this.$t("pup.txt33"),
        this.$t("pup.txt34"),
        this.$t("pup.txt35"),
        this.$t("pup.txt36"),
        this.$t("pup.txt37"),
        this.$t("pup.txt38"),
      ], //成分数据
      radio: "1",
      radio2: "1",
      outImg: require("@/newImg/out.png"),
      outImg2: require("@/newImg/out2.png"),
      backImg: require("@/newImg/outBack.png"),
      backImg2: require("@/newImg/outBack2.png"),
      sceneImg: require("@/newImg/scene.png"),
      sceneImg2: require("@/newImg/scene2.png"),
      modelImg: require("@/newImg/modelType.png"),
      modelImg2: require("@/newImg/modelType2.png"),
      sceneBgType: 1,
      structureImg: "",
      structurecolor: [],
      socksBodyYarnCount: "", //袜身颜色数量
    };
  },
  beforeDestroy() {
    localStorage.removeItem("jacquardWeaveMeanWorkImg");
    localStorage.removeItem("jacquardWeaveMeanWorkSize");
  },
  watch: {
    //切换复选框内容中英文
    "$store.state.index.languageType"(newVal, Val) {
      // 纱线信息
      this.HeadList.forEach((element, index) => {
        this.$set(
          this.HeadList,
          index,
          this.$t(`historyDetail.txt${index + 6}`)
        );
      });
      // 面料信息
      this.fabricInfoData = [];
      this.fabricInfoData.push({
        title: this.$t("historyDetail.txt13"),
        con: this.clothDetail.draperInfo.productCode,
      });
      this.fabricInfoData.push({
        title: this.$t("historyDetail.txt14"),
        con: this.clothDetail.draperInfo.productName,
      });
      this.fabricInfoData.push({
        title: this.$t("historyDetail.txt15"),
        con: this.clothDetail.draperInfo.craftRequire,
      });
      this.fabricInfoData.push({
        title: this.$t("historyDetail.txt16"),
        con: this.clothDetail.draperInfo.ribWidth,
      });
      this.fabricInfoData.push({
        title: this.$t("historyDetail.txt17"),
        con: this.clothDetail.draperInfo.ribHeight,
      });
      this.fabricInfoData.push({
        title: this.$t("historyDetail.txt18"),
        con: this.clothDetail.draperInfo.coamingLength,
      });
      this.fabricInfoData.push({
        title: this.$t("historyDetail.txt19"),
        con: this.clothDetail.draperInfo.coamingCrossWidth,
      });
      this.fabricInfoData.push({
        title: this.$t("historyDetail.txt20"),
        con: this.clothDetail.draperInfo.doffingLength,
      });
      this.fabricInfoData.push({
        title: this.$t("historyDetail.txt21"),
        con: this.clothDetail.draperInfo.doffingCrossWidth,
      });

      let arr = [];
      if (this.fabricInfoData.length % 5 != 0) {
        for (var i = 0; i < 5 - (this.fabricInfoData.length % 5); i++) {
          arr.push({
            title: "",
            con: "",
          });
        }
        this.fabricInfoData.push(...arr);
      }
      this.materialQuality.forEach((item, index) => {
        this.$set(this.materialQuality, index, this.$t(`pup.txt${index + 24}`));
      });
      this.yarnList.forEach((item, index) => {
        this.clothDetail.designConfig.copyYarnList.forEach((el) => {
          if (el.hexCode == item.yarnColor) {
            item.nr = el.nr;
            item.rawMaterials = "";
            item.typeName = "";
            el.component.data.forEach((elItem) => {
              item.rawMaterials += `${elItem.proportion}%${
                this.materialQuality[elItem.componentNum]
              }`;
            });
            item.typeName = this.$t("historyDetail.txt66");
          }
        });
        this.clothDetail.designConfig.positionColorList.forEach(
          (el, elIndex) => {
            if (el.hexCode == item.yarnColor) {
              item.nr = el.nr;
              item.rawMaterials = "";
              item.typeName = "";
              el.component.data.forEach((elItem) => {
                item.rawMaterials += `${elItem.proportion}%${
                  this.materialQuality[elItem.componentNum]
                }`;
              });
              item.typeName = this.$t(`historyDetail.txt${elIndex + 67}`);
            }
          }
        );
      });
    },
  },
  methods: {
    //表头样式
    headerClass() {
      return "background: #F7F7F7;font-weight: 500;color: #333333; font-size: 18px; width:100%";
    },
    arraySpanMethod({ row, column, rowIndex, columnIndex }) {
      // return [row.nameIndex, 1]
      if (columnIndex === 0) {
        //用于设置要合并的列
        // console.log(rowIndex, this.socksBodyYarnCount);
        if (rowIndex < this.socksBodyYarnCount) {
          if (rowIndex % this.socksBodyYarnCount == 0) {
            // console.log(rowIndex);
            return {
              rowspan: this.socksBodyYarnCount,
              colspan: 1,
            };
          } else {
            return {
              rowspan: 0,
              colspan: 0,
            };
          }
        } else {
          return {
            rowspan: 1,
            colspan: 1,
          };
        }
      }
    },
    sendImg(res) {},
    modelChangeHandler(value) {
      // (value-1)*4+num
      this.$refs.renderComp.setAPairModel({
        type: value * 1,
        index: this.clothDetail.designConfig.StructureIndex,
      });
    },
    sceneBgChangeHandler(value) {
      this.sceneBgType = value;
      this.$nextTick(() => {
        if (
          this.$refs.renderComp != undefined &&
          this.$refs.renderComp != "" &&
          this.$refs.renderComp != null
        ) {
          this.$refs.renderComp.setSceneBg(value);
        }
      });
    },
    //返回历史页面

    backBtnClick() {
      this.$router.push("/history");
    },
    //副本
    confirmCopyDesign() {
      localStorage.setItem("clearType", 1);
      this.$router.push({
        path: "/oneIndex",
        query: {
          id: this.clothDetail.draperInfo.designId,
        },
      });
    },

    //  意匠图数据回显

    copyDesign(value) {
      this.isShowDialog = true;
      this.setContent = this.$t("historyDetail.txt46");
      this.setConfirmContent = this.$t("historyDetail.txt47");
      this.currentDialogTitle = value;
      localStorage.setItem("clearType", 0);
    },
    //删除
    deleteClick(value) {
      this.isShowDialog = true;
      this.setContent = this.$t("historyDetail.txt48");
      this.setConfirmContent = this.$t("historyDetail.txt49");
      this.currentDialogTitle = value;
    },
    // 后整工艺处理
    HZClick() {
      return;
    },
    //放入2d图片
    getFrontUrlMap() {
      return {
        "background-image": 'url("' + this.planeImg + '")',
        width: this.sizeNum * 100 + "%",
        height: this.sizeNum * 100 + "%",
        "background-size": this.sizeRepeatX * 100 + "%",
      };
    },
    //修改2d图片大小
    setImgsize(type) {
      if (type == 1) {
        if (this.sizeNum > 1) {
          this.sizeNum--;
        }
      } else {
        if (this.sizeNum < 10) {
          this.sizeNum++;
        }
      }
    },
    //切换正反面
    setplaneImg(type) {
      if (type == 1) {
        this.planeImgtype = true;
        this.planeImg = this.designResultPath.colorFront;
      } else {
        this.planeImgtype = false;
        this.planeImg = this.designResultPath.colorBack;
      }
    },
    cancelDialog(value) {
      this.isShowDialog = value;
    },
    confirmDialog(value) {
      this.isShowDialog = value;
      const { currentDialogTitle } = this;

      if (currentDialogTitle === "copy") {
        this.confirmCopyDesign();
      } else if (currentDialogTitle === "delete") {
        this.delClothInfo();
      }
    },

    // 删除意匠图
    async delDesign() {
      const { code, data } = await delHistory({
        draperyId: this.clothDetail.draperInfo.draperyId,
      });
      //删除成功
      if (code === 0) {
        //await右边必须得是一个promise所以需要promise来卡住定时器的执行
        await new Promise((resolve) => {
          setTimeout(() => {
            resolve();
            this.$message.success(this.$t("historyDetail.txt50"));
          }, 0);
        }).then(() => {});
        this.$router.push({
          path: "/history",
        });
      }
    },
    //调用删除布料信息的方法
    delClothInfo() {
      this.delDesign();
    },
    // 放大模型展示
    FDModelClick() {
      this.showFD = true;
      this.FDrenderType = false;
      this.conmodelType = false;
      this.btnIndex = 0;
      this.scrollTop = document.querySelector(".historyContiner").scrollTop;
      this.$nextTick(() => {
        this.$refs.renderComp.setAPairModel({
          type: 3,
          index: this.clothDetail.designConfig.StructureIndex,
        });
        this.$refs.renderComp.hideModel(2);
      });
    },

    // 缩小模型展示
    SXModelClick() {
      this.btnIndex = 0;
      this.showFD = false;
      this.radio = "1";
      this.radio2 = "1";
      this.sceneBgChangeHandler(1);
      this.$nextTick(() => {
        document.querySelector(".historyContiner").scrollTop = this.scrollTop;
        if (
          this.$refs.renderComp != undefined &&
          this.$refs.renderComp != "" &&
          this.$refs.renderComp != null
        ) {
          this.$refs.renderComp.setAPairModel({
            type: 3,
            index: this.clothDetail.designConfig.StructureIndex,
          });
          this.$refs.renderComp.hideModel(1);
        }
      });
    },
    cancelExport(val) {
      this.isShowExport = val;
    },
    confirmExport(val, selectType) {
      this.isShowExport = val;
      if (selectType == 1) {
        this.$router.push({
          path: `/history/thdownloadpage/?id=${this.clothDetail.draperInfo.draperyId}&type=1`,
        });
      } else if (selectType == 2) {
        this.$router.push({
          path: `/history/thdownloadpage/?id=${this.clothDetail.draperInfo.draperyId}&type=2`,
        });
      } else {
        if (this.designResultPath.operationBmp) {
          this.showType = false;
          window.open(this.designResultPath.operationBmp, "_blank");

          this.t = setTimeout(() => {
            this.showType = true;
            this.IsShowExport = false;
          }, 3000);
        } else {
          this.$message.warning(this.$t("historyDetail.txt51"));
          this.IsShowExport = false;
        }
      }
    },
  },
  computed: {},
  mounted() {
    getClothDetail({
      draperyId: this.$route.params.id,
    }).then((res) => {
      if (res.code === 0) {
        let clothDetailinfo = res.data.draperInfoDetail;
        this.designResultPath = clothDetailinfo.designResultPath;
        this.planeImg = this.designResultPath.colorFront;
        this.structureImg = clothDetailinfo.designConfig.StructureImg;
        this.structurecolor =
          clothDetailinfo.designConfig.StructureImgColorList;
        this.img2D = clothDetailinfo.designResultPath.colorFront;
        var img = new Image();
        var that = this;
        img.src = clothDetailinfo.designResultPath.colorFront;
        img.onload = (imgData) => {
          if (img.width > img.height) {
            that.sizeRepeatX = (img.height / img.width) * 1;
          } else {
            that.sizeRepeatX = (img.width / img.height) * 1;
          }
        };
        this.clothDetail = clothDetailinfo;
        /* clothDetailinfo.designConfig.gauzeList.forEach((item, index) => {
          clothDetailinfo.designConfig.copyYarnList.forEach((el) => {
            if (el.hexCode == item.yarnColor) {
              console.log(index);
              item.nr = el.nr;
              item.rawMaterials = "";
              el.component.data.forEach((elItem) => {
                item.rawMaterials += `${elItem.proportion}%${
                  this.materialQuality[elItem.componentNum]
                }`;
              });
              item.yarnCount =
                el.YarnCount.num + (el.YarnCount.unit == 1 ? "D" : "S");
              this.yarnList.push(item);
            }
          });
        }); */
        let yarnItem;
        clothDetailinfo.designConfig.copyYarnList.forEach((el) => {
          yarnItem = {
            nr: "",
            rawMaterials: "",
            yarnCount: "",
            yarnColor: "",
            typeName: "",
          };
          yarnItem.nr = el.nr;
          yarnItem.yarnColor = el.hexCode;
          el.component.data.forEach((elItem) => {
            yarnItem.rawMaterials += `${elItem.proportion}%${
              this.materialQuality[elItem.componentNum]
            }`;
          });
          yarnItem.yarnCount =
            el.YarnCount.num + (el.YarnCount.unit == 1 ? "D" : "S");
          this.yarnList.push(yarnItem);
          yarnItem.typeName = this.$t("historyDetail.txt66");
        });
        this.socksBodyYarnCount =
          clothDetailinfo.designConfig.copyYarnList.length;
        clothDetailinfo.designConfig.positionColorList.forEach(
          (item, index) => {
            yarnItem = {
              nr: "",
              rawMaterials: "",
              yarnCount: "",
              yarnColor: "",
              typeName: "",
            };
            if (
              index <
              clothDetailinfo.designConfig.positionColorList.length - 1
            ) {
              yarnItem.nr = item.nr;
              yarnItem.yarnColor = item.hexCode;
              item.component.data.forEach((elItem) => {
                yarnItem.rawMaterials += `${elItem.proportion}%${
                  this.materialQuality[elItem.componentNum]
                }`;
              });
              yarnItem.yarnCount =
                item.YarnCount.num + (item.YarnCount.unit == 1 ? "D" : "S");
              yarnItem.typeName = this.$t(`historyDetail.txt${index + 67}`);
              this.yarnList.push(yarnItem);
            }
          }
        );

        this.draperInfo = clothDetailinfo.draperInfo;
        this.designId = clothDetailinfo.draperInfo.designId;
        this.renderType = true;
        this.fabricInfoData.push({
          title: this.$t("historyDetail.txt13"),
          con: clothDetailinfo.draperInfo.productCode,
        });
        this.fabricInfoData.push({
          title: this.$t("historyDetail.txt14"),
          con: clothDetailinfo.draperInfo.productName,
        });
        this.fabricInfoData.push({
          title: this.$t("historyDetail.txt15"),
          con: clothDetailinfo.draperInfo.craftRequire,
        });
        this.fabricInfoData.push({
          title: this.$t("historyDetail.txt16"),
          con: clothDetailinfo.draperInfo.ribWidth,
        });
        this.fabricInfoData.push({
          title: this.$t("historyDetail.txt17"),
          con: clothDetailinfo.draperInfo.ribHeight,
        });
        this.fabricInfoData.push({
          title: this.$t("historyDetail.txt18"),
          con: clothDetailinfo.draperInfo.coamingLength,
        });
        this.fabricInfoData.push({
          title: this.$t("historyDetail.txt19"),
          con: clothDetailinfo.draperInfo.coamingCrossWidth,
        });
        this.fabricInfoData.push({
          title: this.$t("historyDetail.txt20"),
          con: clothDetailinfo.draperInfo.doffingLength,
        });
        this.fabricInfoData.push({
          title: this.$t("historyDetail.txt21"),
          con: clothDetailinfo.draperInfo.doffingCrossWidth,
        });
        let arr = [];
        if (this.fabricInfoData.length % 5 != 0) {
          for (var i = 0; i < 5 - (this.fabricInfoData.length % 5); i++) {
            arr.push({
              title: "",
              con: "",
            });
          }
          this.fabricInfoData.push(...arr);
        }
        this.$nextTick(() => {
          if (
            this.$refs.renderComp != undefined &&
            this.$refs.renderComp != "" &&
            this.$refs.renderComp != null
          ) {
            this.$refs.renderComp.setMODEL({
              index: clothDetailinfo.designConfig.StructureIndex,
              active: 3,
            });
          }
        });
        let StructureImgColorList =
          clothDetailinfo.designConfig.StructureImgColorList;
        let addStructureList = clothDetailinfo.designConfig.addStructureList;
        let structureList = [];
        let numType = false;
        let numIndex = 0;
        StructureImgColorList.forEach((item, i) => {
          addStructureList.forEach((el, k) => {
            if (item.index != undefined) {
              if (item.index == el.index) {
                structureList.forEach((el, o) => {
                  if (item.index == el.index) {
                    numType = true;
                  }
                });
                if (!numType) {
                  structureList.push({
                    keyList: el.Structure,
                    index: item.index,
                    colorList: [item.rgb],
                  });
                }
                numType = false;
              }
            }
          });
        });
        let numArr = [];
        // let structureList = [];
        /*  StructureImgColorList.forEach((item, i) => {
          if (item.index != undefined) {
            numArr.push(item.index);
          }
        }); */

        /* numArr.forEach((item, i) => {
          StructureImgColorList.forEach((items, j) => {
            if (items.index != undefined) {
              if (item == items.index) {
                structureList.forEach((el, o) => {
                  if (item == el.index) {
                    numType = true;
                    numIndex = o;
                  }
                });
                if (numType) {
                  if (
                    structureList[numIndex].colorList.indexOf(items.hexCode) ==
                    -1
                  ) {
                    structureList[numIndex].colorList.push(items.hexCode);
                  }
                } else {
                  structureList.push({
                    keyList: items.structure.structure,
                    index: items.index,
                    colorList: [items.hexCode],
                  });
                }
                numType = false;
              }
            }
          });
        }); */

        let colorList = [];
        if (structureList.length == 0 && this.structurecolor.length != 0) {
          this.structurecolor.forEach((item, index) => {
            colorList.push(item.rgb);
          });
          structureList.push({
            index: 0,
            keyList: [
              [1, 1],
              [1, 1],
            ],
            colorList: colorList,
          });
        }
        this.structureList = structureList;
        if (this.structureList % 2 != 0) {
          this.structureListBorderType = this.structureList - 2;
        }
      }
    });
  },
};
</script>

<style scoped lang="less">
@import "./THHistoryDetail.less";
</style>
<style lang="less">
.el-popover.modelPopover {
  width: 162px;
  z-index: 5000 !important;
  background: #4d4d4d;
  padding: 0;
  border: none;
  .popper__arrow {
    border-top-color: #4d4d4d;
  }
  .popper__arrow::after {
    border-top-color: #4d4d4d;
  }
  .popperTop {
    height: 38px;
    border-bottom: 1px solid #666666;
    padding-left: 15px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    span {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 18px;
      color: #ffffff;
      line-height: 25px;
      text-align: left;
      font-style: normal;
    }
  }
  .popperContent {
    padding: 14px 0 14px 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    .el-radio-group {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      .el-radio {
        .el-radio__label {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 18px;
          color: #ffffff;
          line-height: 25px;
          text-align: left;
          font-style: normal;
          padding-left: 7px;
        }
        .el-radio__input.is-checked + .el-radio__label {
          color: #ffffff;
        }
        .el-radio__inner {
          width: 16px;
          height: 16px;
          border: 1px solid #979797;
          background-color: #4d4d4d;
          box-sizing: border-box;
          position: relative;
        }
        .el-radio__inner::after {
          width: 8px;
          height: 8px;
          transform: translate(-50%, -50%) scale(0);
        }
        .el-radio__input.is-checked .el-radio__inner::after {
          transform: translate(-50%, -50%) scale(1);
        }
      }
      .oneRadio {
        margin-bottom: 20px;
      }
    }
  }
}
.el-popover.modelPopover2 {
  background: #adaeb2;
  .popper__arrow {
    border-top-color: #adaeb2;
  }
  .popper__arrow::after {
    border-top-color: #adaeb2;
  }
  .popperTop {
    border-bottom: 1px solid #c4c4c4;
    span {
      color: #333333;
    }
  }
  .popperContent {
    .el-radio-group {
      .el-radio {
        .el-radio__label {
          color: #333333;
        }
        .el-radio__input.is-checked + .el-radio__label {
          color: #333333;
        }
        .el-radio__inner {
          border: 1px solid #7b7b7b;
          background-color: #adaeb2;
        }
        .el-radio__inner::after {
          background: #333333;
        }
      }
    }
  }
}
</style>