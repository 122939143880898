<template>
  <div>
    <div class="structureSplice">
      <p class="title">{{ $t("historyDetail.txt27") }}</p>
      <div class="content">
        <span class="structureText">{{ $t("historyDetail.txt59") }}</span>
        <div class="structureImgTop">
          <div class="structureImg">
            <!-- <img :src="structureImg" alt="" /> -->
            <el-image
              style="width: 98%; height: 98%"
              :src="structureImg"
              fit="contain"
              ><div slot="error" class="image-slot">
                <!-- <i class="el-icon-picture-outline"></i> -->
                <img src="@/newImg/structure.png" alt="" />
                <span>{{ $t("historyDetail.txt70") }}</span>
              </div></el-image
            >
          </div>
          <div class="structureColor">
            <div
              v-for="(item, index) in structurecolor"
              :key="index"
              class="itemColor"
            >
              <span class="num">{{ index + 1 }}.</span>
              <span
                class="color"
                :style="{ background: `rgb(${item.rgb})` }"
              ></span>
              <span class="name"></span>
            </div>
          </div>
        </div>
        <div
          class="itemLine"
          v-for="(item, index) in Math.ceil(structureList.length / 2)"
          :key="index"
        >
          <div class="itemLeft itemContent">
            <div class="structureList">
              <div
                class="item"
                :style="`${
                  copystructureList.length == 0
                    ? ''
                    : copystructureList[index * 2].w
                    ? 'align-items: start;'
                    : ''
                }${
                  copystructureList.length == 0
                    ? ''
                    : copystructureList[index * 2].h
                    ? ' justify-content: start;'
                    : ''
                }`"
              >
                <div
                  v-for="(itemW, indexW) in structureList[index * 2].keyList"
                  :key="indexW + 100"
                  class="itemW"
                >
                  <div
                    v-for="(itemH, indexH) in itemW"
                    :key="indexH + 200"
                    :class="
                      indexH == 0
                        ? indexW == 0
                          ? 'itemH leftBorder topBorder'
                          : 'itemH leftBorder'
                        : indexW == 0
                        ? 'itemH topBorder'
                        : 'itemH'
                    "
                  >
                    <img :src="thStrList[itemH - 1]" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div class="colorlist">
              <div
                v-for="(itemW, indexW) in structureList[index * 2].colorList"
                :key="indexW + 300"
                class="itemColor"
                :style="{ background: `rgb(${itemW})` }"
              ></div>
            </div>
          </div>
          <div
            class="itemRight itemContent"
            v-if="structureList[index * 2 + 1]"
          >
            <div class="structureList">
              <div
                class="item"
                :style="`${
                  copystructureList.length == 0
                    ? ''
                    : copystructureList[index * 2 + 1].w
                    ? 'align-items: start;'
                    : ''
                }${
                  copystructureList.length == 0
                    ? ''
                    : copystructureList[index * 2 + 1].h
                    ? 'justify-content: start;'
                    : ''
                }`"
              >
                <div
                  v-for="(itemW, indexW) in structureList[index * 2 + 1]
                    .keyList"
                  :key="indexW + 100"
                  class="itemW"
                >
                  <div
                    v-for="(itemH, indexH) in itemW"
                    :key="indexH + 200"
                    :class="
                      indexH == 0
                        ? indexW == 0
                          ? 'itemH leftBorder topBorder'
                          : 'itemH leftBorder'
                        : indexW == 0
                        ? 'itemH topBorder'
                        : 'itemH'
                    "
                  >
                    <img :src="thStrList[itemH - 1]" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div class="colorlist">
              <div
                v-for="(itemW, indexW) in structureList[index * 2 + 1]
                  .colorList"
                :key="indexW + 300"
                class="itemColor"
                :style="{ background: `rgb(${itemW})` }"
              ></div>
            </div>
          </div>
        </div>
        <!-- <div class="centerBorder"></div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "structure",
  props: [
    "structureList",
    "thStrList",
    "structureListBorderType",
    "structureImg",
    "structurecolor",
  ],
  data() {
    return {
      test: [
        {
          name: "jiegou1",
          width: "6",
          height: "4",
          keyList: [
            [1, 1, 1, 1, 1, 1],
            [1, 2, 1, 3, 1, 1],
            [1, 1, 2, 1, 2, 1],
            [1, 1, 1, 1, 1, 1],
          ],
          canvasKey: [9],
          colorList: [
            {
              hexCode: "#1B538F",
              name: "Snorkel Blue",
              nr: "PANTONE 19-4049 TPG",
              rgb: "27,83,142",
            },
            {
              hexCode: "#2D5593",
              name: "Snorkel Blue",
              nr: "PANTONE 19-4049 TPG",
              rgb: "27,83,142",
            },
            {
              hexCode: "#415997",
              name: "Snorkel Blue",
              nr: "PANTONE 19-4049 TPG",
              rgb: "27,83,142",
            },
          ],
        },
        {
          name: "jiegoy2",
          width: "5",
          height: "4",
          keyList: [
            [1, 1, 1, 1, 1],
            [1, 1, 1, 2, 1],
            [1, 3, 1, 1, 1],
            [1, 1, 1, 1, 1],
          ],
          canvasKey: [4],
        },
        {
          name: "jieouy3",
          width: "6",
          height: "6",
          keyList: [
            [1, 1, 1, 1, 1, 1],
            [1, 2, 1, 1, 2, 1],
            [1, 1, 2, 1, 2, 1],
            [1, 2, 1, 1, 1, 1],
            [1, 1, 1, 1, 3, 1],
            [1, 1, 1, 1, 1, 1],
          ],
          canvasKey: [6, 10],
        },
      ],
      copystructureList: [],
    };
  },
  watch: {
    structureList(newVal) {
      this.$nextTick(() => {
        this.copystructureList = newVal;
        let elstructureList = document.querySelector(".structureList");
        let el = document.querySelector(".itemH");
        this.copystructureList.forEach((item, index) => {
          if (
            item.keyList[0].length * el.offsetWidth >=
            elstructureList.offsetWidth
          ) {
            this.$set(this.copystructureList[index], "w", true);
          } else {
            this.$set(this.copystructureList[index], "w", false);
          }
          if (
            item.keyList.length * el.offsetHeight >=
            elstructureList.offsetHeight
          ) {
            this.$set(this.copystructureList[index], "h", true);
          } else {
            this.$set(this.copystructureList[index], "h", false);
          }
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
// 组织结构拼接图
.structureSplice {
  background-color: #ffffff;
  width: 100%;
  border-radius: 8px;
  padding-left: 82px;
  padding-right: 72px;
  box-sizing: border-box;
  padding-bottom: 38px;
  padding-top: 38px;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(102, 102, 102, 0.08);

  .title {
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 22px;
    color: #333333;
    line-height: 20px;
    text-align: left;
    font-style: normal;
    margin-bottom: 46px;
  }

  .content {
    position: relative;
    .itemLine {
      display: flex;
      // align-items: center;
      justify-content: space-between;
      margin-bottom: 96px;

      .itemContent {
        width: 842px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        position: relative;

        .structureName {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 95;
          width: 155px;
          height: 42px;
          background: #41414e;
          display: flex;
          align-items: center;
          justify-content: center;

          span {
            font-size: 20px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            line-height: 28px;
            letter-spacing: 2px;
            text-overflow: ellipsis;
            display: inline-block;
            overflow: hidden;
          }
        }

        .structureList {
          width: 100%;
          height: 435px;
          background: #f2f4f7;
          border-radius: 4px;
          // display: flex;
          // align-items: center;
          // justify-content: center;
          .item {
            overflow: auto;
            display: flex;
            flex-direction: column;
            width: 98%;
            height: 98%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 1%;
            margin-left: 1%;

            .itemW {
              display: flex;
              .itemH {
                width: 26px;
                height: 26px;
                box-sizing: border-box;
                border-bottom: 1px solid #e0e0e0;
                border-right: 1px solid #e0e0e0;
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                  width: 20px;
                  height: 20px;
                }
              }
            }

            .topBorder {
              width: auto;
              border-top: 1px solid #e0e0e0;
            }

            .leftBorder {
              border-left: 1px solid #e0e0e0;
            }
          }

          .item::-webkit-scrollbar {
            width: 4px;
            height: 4px;
            position: absolute;
            right: 0;
            top: 0;
            border-radius: 4px;
            cursor: pointer;
          }

          /* 滚动槽 */
          .item::-webkit-scrollbar-track {
            border-radius: 4px;
            cursor: pointer;
          }

          /* 滚动条滑块 */
          .item::-webkit-scrollbar-thumb {
            border-radius: 2px;
            cursor: pointer;
            background: #666;
            background: rgba(0, 0, 0, 0.24);
            position: relative;
            z-index: 3;
          }

          /* 滚动条滑块 */
          .item::-webkit-scrollbar-thumb:hover {
            background: rgba(0, 0, 0, 0.4);
          }
        }

        .colorlist {
          width: 100%;
          // height: 80px;
          margin-top: 10px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          .itemColor {
            width: 45px;
            height: 45px;
            margin-right: 14px;
            border-radius: 4px;
            box-sizing: border-box;
            border: 1px solid #dedede;
          }
        }
      }

      .itemLeft {
        width: 779px;
        // height: 435px;
        // background: #f2f4f7;
        box-sizing: border-box;
        position: relative;
        overflow: hidden;

        .border {
          position: absolute;
          width: 1px;
          height: 100%;
          top: 0;
          right: -64px;
          background: #f1f1f1;
        }
      }

      .itemRight {
        padding: 0px 0 0px 0;
        width: 779px;
        // height: 435px;
        // background: #f2f4f7;
        // border-radius: 4px;
        box-sizing: border-box;
        overflow: hidden;

        .structureName {
          position: absolute;
          left: 0;
          top: 0px;
        }
      }
    }

    .centerBorder {
      width: 1px;
      height: 435px;
      background: #f1f1f1;
    }
    .structureText {
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 20px;
      color: #333333;
      line-height: 20px;
      text-align: left;
      font-style: normal;
    }
    .structureImgTop {
      margin-top: 21px;
      display: flex;
      // align-items: center;
      justify-content: space-between;
      margin-bottom: 96px;
      .structureImg {
        width: 779px;
        height: 700px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: #f7f7f7;
        border-radius: 4px;
        img {
          height: 100%;
        }
        /deep/.image-slot {
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          img {
            width: 75px;
            height: 91px;
            margin-bottom: 22px;
          }
          span {
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 20px;
            color: #B1B1B1;
            line-height: 28px;
            text-align: left;
            font-style: normal;
          }
        }
      }
      .structureColor {
        width: 779px;
        height: 700px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        align-content: flex-start;
        flex-wrap: wrap;
        justify-content: flex-start;
        .itemColor {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          margin-bottom: 30px;
          span {
            width: 30px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 20px;
            color: #333333;
          }
          .color {
            width: 45px;
            height: 45px;
            margin: 0 20px;
            border-radius: 4px;
            box-sizing: border-box;
            border: 1px solid #dedede;
          }
        }
      }
    }
  }
}
</style>>